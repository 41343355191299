window.$ = window.jQuery = require('jquery');
require ('remodal');
require ('owl.carousel2');



$(document).ready(function() {

	$(document).on('click', '.nav-icon', function(event) {
        event.preventDefault();
        $(this).toggleClass('nav-icon-close');
        $('.menu').toggleClass('menu-shown');

        $('nav').toggleClass('nav-opened');
    });


});